/* =======================================================
*
* 	OPEN API
*
* ======================================================= */

.homepage {
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  height: auto;
  background-image: url("./assets/Pressoir-Tourgeville-2.jpg");
}

.color-mariage{
 background-color: #c04c36;
}

.mariagephoto {
  object-fit: cover;
  max-height : 25rem;
  max-width : 100%;
  border-radius: 1rem!important;
  }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-header-logo {
  display: inline-block;
}

.app-header-logo img {
  height: 20px;
}

.btn-kease {
  background-color: #c04c36;
  color : #ffffff;
}

.btn.btn-kease {
  color: #fff;
  background-color: #c04c36;
  border-color: #c04c36;
}
.btn.btn-kease.focus,
.btn.btn-kease:focus {
  color: #fff;
  background-color: #c04c36;
  border-color: #c04c36;
  outline: none;
  box-shadow: none;
}
.btn.btn-kease:hover {
  color: #fff;
  background-color: #c04c36;
  border-color: #c04c36;
  outline: none;
  box-shadow: none;
}
.btn.btn-kease.active,
.btn.btn-kease:active {
  color: #fff;
  background-color: #c04c36;
  border-color: #c04c36;
  outline: none;
}
.btn.btn-kease.active.focus,
.btn.btn-kease.active:focus,
.btn.btn-kease.active:hover,
.btn.btn-kease:active.focus,
.btn.btn-kease:active:focus,
.btn.btn-kease:active:hover {
  color: #fff;
  background-color: #c04c36;
  border-color: #c04c36;
  outline: none;
  box-shadow: none;
}

.kease-font {
  background-color: #c04c36;
  color : #ffffff;
}

.btn-kease:hover {
  background-color: #ffffff;
  color : #c04c36;
}

.text-kease {
  color : #c04c36;
}

.tick-marck-ul {
  list-style: none;
  font-weight: 900;
}

.tick-marck-ul li:before {
  content: url(assets/tickmark.svg);
  width: 25px;
  height: 25px;
  font-size: 1.6em;
  margin-right: 2%;
  margin-left: 10%;
  display: inline-block;
}

.rounded-top-kease {
  border-top-left-radius: 2rem!important;
  border-top-right-radius: 2rem!important;
}

.rounded-bottom-kease {
  border-bottom-left-radius: 2rem!important;
  border-bottom-right-radius: 2rem!important;
}

.btn-kease {
  background-color: #c04c36;
  color : #ffffff;
}

.font-kease:hover .kease-hover  {
  background-color: #c04c36;
  border-color : #c04c36;
  color : #ffffff;
}

.font-kease:hover .btn-kease {
  background-color: #ffffff;
  color : #c04c36;
  border-color : #ffffff;
}

.progress .progress-left {
  left: 0;
}

.footer-kease {
  margin: 0px;
  margin-top: 0px;
}

.card-kease:hover {
  background-color: #c04c36;
  border-color : #c04c36;
  color : #ffffff;
}

.card-kease:hover .btn-kease {
  background-color: #ffffff;
  color : #c04c36;
  border-color : #ffffff;
}


a {
  outline: none;
  text-decoration: none;
  padding: 2px 1px 0;
  color: #000000;
}

.text-link-kease {
  font-size: 12px !important;
}

a:hover {
  color: #c04c36;
}

/* =======================================================
*
* 	PROGRESS BAR - PENSER AU DINAMYQUE POUR SIMPLIFIER
*
* ======================================================= */

.progress-circle {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #e0e0e0;
  overflow: hidden;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #4caf50;
  transform-origin: center;
  transform: rotate(var(--progress)deg);
}

/* =======================================================
*
* 	PRESENTATION
*
* ======================================================= */

.imgkease {
  object-fit: cover;
  height : auto;
  max-width : 20%;
  border-radius: 1rem!important;
  }

  .features:hover {
    background-color: #c04c36;
    border-color : #c04c36;
    color : #ffffff;
  }

  .features:hover div[role="progressbar"] {
    --fg: white;
    --bg: #c04c36;
    background: 
      radial-gradient(closest-side, #c04c36 80%, transparent 0 99.9%, #c04c36 0),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0)
      ;
  }
  .roudedcard {
    border-radius: 1rem!important;
  }
/* =======================================================
*
* 	SIDEBAR
*
* ======================================================= */


.countdown_card {
  text-align: center;
}

.countdown_section {
  display: inline-block;
  margin-left: 1px;
}

.countdown_section:first-child {
  margin-left: 0;
}

.countdown_section .countdown_value {
  background-color: #c04c36;
  border-radius: 10px;
  font-size: 50px;
  color: #ffffff;
  line-height: 100px;
  text-align: center;
  height: 100px;
  width: 100px;
}


.countdown_label {
  font-size: 11px;
  padding-top: 4px;
  text-align: center;
}

.border-kease {
  border-color: #c04c36!important;
}

.bg-kease {
  background-color: #c04c36!important;
}

.countdown_section .iphonecd_value {
  background-color: #c04c36;
  border-radius: 10px;
  font-size: 18px;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  height: 50px;
  width: 50px;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23c04c36' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z'/%3E%3C/svg%3E") !important;
 }
 
 .carousel-control-next-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23c04c36' viewBox='0 0 16 16'%3E%3Cpath d='M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z'/%3E%3C/svg%3E") !important;
 }

 .text-white{
  color: #ffffff !important;
}